import React from 'react';


export default ({ children, icon, iconAlt }) => (
  <div className="section_contact-details--wrapper">
    <img className="section_contact-image" src={icon} alt={iconAlt} />
    <div className="section_contact-text">
      {children}
    </div>
  </div>
);
