import React from 'react';
// import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { GoogleMap, LoadScript } from '@react-google-maps/api';

import { googleMaps } from '../../settings';


export default () => (
  <div className="container section_contact-map">
    <LoadScript
      googleMapsApiKey={googleMaps.API_KEY}
    >
      <GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '50vh',
        }}
        zoom={15}
        center={googleMaps.COORDS}
      />
    </LoadScript>
  </div>
);
