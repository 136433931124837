import React from 'react';
import { getCurrentLangKey } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import 'intl';

import messages from '../../data/messages';

import './app.css';


export default ({ children, location, language }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `)
  const { langs, defaultLangKey } = data.site.siteMetadata.languages;
  const url = location.pathname;
  const langKey = getCurrentLangKey(langs, defaultLangKey, url);
  
  return (
    <IntlProvider
      locale={langKey}
      messages={messages[language]}
    >
      {children}
    </IntlProvider>
  );
};
