module.exports = {
  "menuSection.products": "Products",
  "menuSection.history": "History",
  "menuSection.cooperation": "Cooperation",
  "menuSection.team": "Team",
  "menuSection.contact": "Contact",
  "ourProductsSection.productNameFirst": "Tokaj Wine",
  "ourProductsSection.productDescriptionFirst": "Classic Tokay wines are produced by oxidation. They have a rich taste and aroma.",
  "ourProductsSection.productNameSecond": "Grand Selection Wine",
  "ourProductsSection.productDescriptionSecond": "The wines under the Grand Selection range represent a unique blend of the local varieties of Tokaji and the Region's terroir in which mineral nature, vine character, modern technology and the winemaker's expertise are the guarantee of quality and recognition.",
  "ourProductsSection.productNameThird": "Traditional Tokaj Wine",
  "ourProductsSection.productDescriptionThird": "Our wines with the usual well-known quality, in traditional packaging. Thanks to reductive techniques, our your wines are crisp, fruity, and may be a favourite for many wine-lover even on weekdays.",
  "ourProductsSection.buttonShowMore": "More",
  "aboutUsSection.heading": "A few words about us",
  "aboutUsSection.headingHistory": "Since 1990 on the market",
  "aboutUsSection.textHistory": "We have been on the market since 1990 when, for the first time together, we decided to open to the Polish market and then the European one. As a family-owned company, we have expanded each year by conquering new contracts, getting to know foreign markets. Each of us knew their strengths and weaknesses, thanks to which we complemented each other and shared our responsibilities.",
  "aboutUsSection.headingCurrent": "The years pass and we are still moving forward",
  "aboutUsSection.textCurrent": "Our food has been monitored by TUV Rheinland for years, thanks to which our customers can be sure of the quality of our products. We started with the Polish market but at the moment we are working on markets such as: Hungary, France and Italy. We are not closing into new markets, we are open to clients from all over the world.",
  "cooperationSection.heading": "Cooperation",
  "cooperationSection.quality": "Our food is under constant monitoring of the quality of agricultural and food products carried out by TÜV Rheinland Polska",
  "cooperationSection.approach": "Flexibility in the approach and the efficiency of the implementation of the actions taken",
  "cooperationSection.discounts": "We offer attractive price discounts and favorable payment terms to our Regular Recipients",
  "cooperationSection.contactUs": "If you want to know all the benefits of cooperation, please contact us",
  "cooperationSection.recognitions": "We are a reliable company",
  "teamSection.partner": "Partner",
  "teamSection.managingDirector": "Managing Director",
  "teamSection.position": "Position",
  "contactSection.heading": "Contact",
  "contactSection.legal_entity_type": "Spółka z ograniczoną odpowiedzialnością spółka komandytowa",
  "contactSection.openingHours": "Office Opening Hours",
  "contactSection.openingDays": "Monday - Friday",
  "contactSection.phone": "tel.:",
  "contactSection.fax": "fax:",
  "contactSection.street": "ul.",
  "contactSection.other_sites": "Our other webpages",
  "meta.description": "Center Cracow - Your trade partner!",
  "meta.keywords": "lemon, juice, liquid lemon spice, popcorn kernel, popcorn, cereal, corn flour, popcorn corn, wholesale, food popcorn, citromle, lemon concentrate, sunflower, wine, champagne, concentrates, peas, soybean, cc, center, cracow, reliable partner, import, export, trade, hungary, spelt, spelt flour, lacrimae christi, mass wine, christus rex, maxi corn, weaver popcorn, fertilizer, fertilizers, agricultural fertilizers, mineral fertilizers, npk, Polifoska 8, Polifoska 6, utra 10, tarnogran"
};
