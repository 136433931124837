import React from 'react';
import { FormattedMessage } from 'react-intl';

import imageEmail from '../../assets/img/email.svg';
import imagePhone from '../../assets/img/phone.svg';
import imageClock from '../../assets/img/clock.svg';
import imageLocalization from '../../assets/img/localization.svg';

import Map from './Map';
import Contact from './Contact';


export default () => (
  <footer className="section_contact" id="section-contact">
    <div className="section_contact-wrapper">
      <h3 className="section_contact-heading">
        <FormattedMessage id="contactSection.heading" />
      </h3>

      <div className="section_contact-company_name_wrapper">
        <div className="section_contact-company_name">Center Cracow</div>
        <p><FormattedMessage id="contactSection.legal_entity_type" /></p>
      </div>
      <div className="section_contact-details--container">
        <Contact
          icon={imageClock}
          iconAlt="ikona-godzin-otwarcia"
        >
          <span className="section_contact-details">
            <FormattedMessage id="contactSection.openingHours" />
          </span>
          <span className="section_contact-details section_contact-details--emphasized">
            <FormattedMessage id="contactSection.openingDays" /> : 8:30 - 15:30
          </span>
        </Contact>

        <Contact
          icon={imagePhone}
          iconAlt="ikona-kontaktu-telefonicznego"
        >
          <span className="section_contact-details">
            <span className="section_contact-phone">
              <span className="section_contact-phone_title"><FormattedMessage id="contactSection.phone" /></span>
              <span className="section_contact-details section_contact-details--emphasized">
                <a href="tel:+48 12 6440034">+48 12 6440034</a>, <a href="tel:+48 12 6446676">+48 12 6446676</a>
              </span>
            </span>
            <span className="section_contact-phone">
              <span className="section_contact-phone_title"><FormattedMessage id="contactSection.fax" /></span>
              <span className="section_contact-details section_contact-details--emphasized">
                <a href="tel:+48 12 6432629">+48 12 6432629</a>, <a href="tel:+48 12 6448706">+48 12 6448706</a>
              </span>
            </span>
          </span>
        </Contact>

        <Contact
          icon={imageLocalization}
          iconAlt="ikona-adresu-biura"
        >
          <span className="section_contact-details">
            <FormattedMessage id="contactSection.street" /> Makuszyńskiego 4
          </span>
          <span className="section_contact-details">31–752 Kraków</span>
        </Contact>

        <Contact
          icon={imageEmail}
          iconAlt="ikona-adresu-mailowego"
        >
          <span className="section_contact-details section_contact-details--emphasized">
            <a href="mailto:center@centercracow.com.pl" className="section_contact-mail">
              center@centercracow.com.pl
            </a>
          </span>
        </Contact>
      </div>

      <Map />

      <div className="section_contact-other_sites">
        <strong><FormattedMessage id="contactSection.other_sites" /></strong>
        <a href="//www.tokaj.pl">www.tokaj.pl</a><a href="//www.weaverpopcorn.pl">www.weaverpopcorn.pl</a>
      </div>
    </div>
  </footer>
);