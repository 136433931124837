module.exports = {
  "menuSection.products": "Produkty",
  "menuSection.history": "Historia",
  "menuSection.cooperation": "Współpraca",
  "menuSection.team": "Team",
  "menuSection.contact": "Kontakt",
  "ourProductsSection.productNameFirst": "Wino Tokaj",
  "ourProductsSection.productDescriptionFirst": "Klasyczne tokajskie wina wytwarzane są przez utlenianie. Mają bogaty smak i aromat.",
  "ourProductsSection.productNameSecond": "Wino Grand Selection",
  "ourProductsSection.productDescriptionSecond": "Wina z asortymentu Grand Selection stanowią unikalne połączenie lokalnych odmian Tokaji i regionu Terroir, w którym natura, charakter winorośli, nowoczesna technologia i wiedza producenta win są gwarancją jakości i uznania.",
  "ourProductsSection.productNameThird": "Tradycyjne Wina Tokajskie",
  "ourProductsSection.productDescriptionThird": "Wina o dobrze znanej jakości, w tradycyjnym opakowaniu. Dzięki technikom redukcyjnym są ostre, owocowe i mogą być ulubionym winem dla wielu miłośników, nawet w dni powszednie.",
  "ourProductsSection.buttonShowMore": "Więcej",
  "aboutUsSection.heading": "Kilka słów o nas",
  "aboutUsSection.headingHistory": "Od 1990 roku na rynku",
  "aboutUsSection.textHistory": "Istniejemy na rynku już od 1990 roku, kiedy po raz pierwszy wspólnymi siłami postanowiliśmy podbić rynek Polski, a następnie Europejski. Jako Rodzinna firma rozwijaliśmy się każdego roku, podpisując nowe kontrakty, poznając rynki zagraniczne. Każdy z nas znał swoje mocne i słabe strony, dzięki czemu nawzajem się uzupełnialiśmy i dzieliliśmy obowiązkami.",
  "aboutUsSection.headingCurrent": "Lata mijają, a my wciąż idziemy na przód",
  "aboutUsSection.textCurrent": "Od lat nasza żywność jest monitorowana przez TUV Rheinland, dzięki czemu nasi klienci mogą być pewni jakości  naszych produktów. Zaczynaliśmy w Polsce, jednak w tym momencie pracujemy na takich rynkach, jak : Węgry, Francja, Włochy. Nie zamykamy się na kolejne rynki, jesteśmy otwarci na klientów z całego świata.",
  "cooperationSection.heading": "Współpraca",
  "cooperationSection.quality": "Nasza żywność jest pod stałym monitoringiem jakości artykułów rolno-spożywczych, prowadzonym przez TÜV Rheinland Polska",
  "cooperationSection.approach": "Elastyczność w podejściu oraz sprawność w realizacji podjętych działań",
  "cooperationSection.discounts": "Stałym Odbiorcom proponujemy atrakcyjne upusty cenowe oraz korzystne terminy płatności",
  "cooperationSection.contactUs": "Jeśli chcesz poznać wszystkie korzyści, wynikające ze wspołpracy, skontaktuj się z nami",
  "cooperationSection.recognitions": "Jesteśmy rzetelną firmą",
  "teamSection.partner": "Partner",
  "teamSection.managingDirector": "Dyrektor Zarządzający",
  "teamSection.position": "Stanowisko",
  "contactSection.heading": "Kontakt",
  "contactSection.legal_entity_type": "Spółka z ograniczoną odpowiedzialnością spółka komandytowa",
  "contactSection.openingHours": "Godziny Otwarcia Biura",
  "contactSection.openingDays": "Poniedziałek - Piątek",
  "contactSection.phone": "tel.:",
  "contactSection.fax": "fax:",
  "contactSection.street": "ul.",
  "contactSection.other_sites": "Inne nasze strony:",
  "meta.description": "Center Cracow - Twój partner w handlu!",
  "meta.keywords": "zaprawa cytrynowa, cytrynka, cytryna, sok, ziarno kukurydzy, popcorn, popkorn, kasza, mąka kukurydziana, kukurydza popcorn, hurt, sprzedaż hurtowa, popcorn spożywczy, citromle, koncentrat cytrynowy, słonecznik, wina, wino, szampany, koncentraty, groszek, groch, soja, cc, center, cracow, solidny partner, import, eksport, handel, węgry, hungary, orkisz, mąki orkiszowe, mąka orkiszowa, lacrimae christi, wino mszalne, christus rex, maxi corn, weaver popcorn, nawóz, nawozy, nawozy rolnicze, nawozy mineralne, npk, polifoska 8, Polifoska 6, utra 10, tarnogran"
};
