module.exports = {
  "menuSection.products": "Termékek",
  "menuSection.history": "Történelem",
  "menuSection.cooperation": "Együttműködés",
  "menuSection.team": "Csapat",
  "menuSection.contact": "Kapcsolat",
  "ourProductsSection.productNameFirst": "Tokaji Borok",
  "ourProductsSection.productDescriptionFirst": "A klasszikus tokaji borokat oxidációval állítják elő. Gazdag íze és aromája van.",
  "ourProductsSection.productNameSecond": "Grand Selection Borok",
  "ourProductsSection.productDescriptionSecond": "A Grand Selection termékcsalád alá tartozó borok egyedülálló keverékét alkotják a Tokaji helyi és a régió terroir fajtái, amelyekben az ásványi természet, a szőlőfajták, a modern technológia és a borász szakértelmének garanciája a minőség és az elismerés.",
  "ourProductsSection.productNameThird": "Klasszikus Tokaji Borok",
  "ourProductsSection.productDescriptionThird": "A borok a szokásos, jól ismert minőségben, hagyományos csomagolásban. A reduktív technikáknak köszönhetően boraink ropogósak, gyümölcsösek, és hétköznapokon is kedvelik a borászokat.",
  "ourProductsSection.buttonShowMore": "Több",
  "aboutUsSection.heading": "Néhány szó rólunk",
  "aboutUsSection.headingHistory": "1990 óta a piacon",
  "aboutUsSection.textHistory": "1990 óta léteztünk a piacon, amikor először közösen elhatároztuk, hogy megnyílik a lengyel piacon, majd az európai piacon. Mint családi vállalkozás, évente bővítettünk új szerződéseket, ismerkedtünk meg a külföldi piacokkal. Mindannyian tudtuk erősségeiket és gyengeségeiket, amelyeknek köszönhetően kiegészítettük egymást és megosztottuk a felelősségünket.",
  "aboutUsSection.headingCurrent": "Az évek elmúlnak, és még mindig haladunk előre",
  "aboutUsSection.textCurrent": "Élelmiszerünket évek óta a TÜV Rheinland felügyeli, melynek köszönhetően ügyfeleink biztosak lehetnek termékeink minőségében. A lengyel piacon indultunk, de jelenleg olyan piacokon dolgozunk, mint például: Magyarország, Franciaország és Olaszország. Nem zárjuk be az új piacokat, nyitottak vagyunk az ügyfelek számára a világ minden tájáról.",
  "cooperationSection.heading": "Együttműködés",
  "cooperationSection.quality": "Élelmiszerünk folyamatosan figyelemmel kíséri a TÜV Rheinland Polska által a mezőgazdasági és élelmiszeripari termékek minőségét",
  "cooperationSection.approach": "A megközelítés rugalmassága és a végrehajtott intézkedések végrehajtásának hatékonysága",
  "cooperationSection.discounts": "Kedvező árengedményeket és kedvező fizetési feltételeket kínálunk rendszeres címzettünknek",
  "cooperationSection.contactUs": "Ha szeretné tudni az együttműködés minden előnyét, kérjük, lépjen velünk kapcsolatba",
  "cooperationSection.recognitions": "Mi megbízható vállalat vagyunk",
  "teamSection.partner": "Partner",
  "teamSection.managingDirector": "Vezérigazgató",
  "teamSection.position": "Pozíció",
  "contactSection.heading": "Kapcsolat",
  "contactSection.legal_entity_type": "Spółka z ograniczoną odpowiedzialnością spółka komandytowa",
  "contactSection.openingHours": "Az Office nyitvatartási ideje",
  "contactSection.openingDays": "Hétfő - Péntek",
  "contactSection.phone": "tel.:",
  "contactSection.fax": "fax:",
  "contactSection.street": "ul.",
  "contactSection.other_sites": "A többi weboldalunk:",
  "meta.description": "Center Cracow - Kereskedelmi Partnere!",
  "meta.keywords": "citrom, gyümölcslé, folyékony citromfűszer, pattogatott kukorica mag, pattogatott kukorica, gabona, kukoricaliszt, pattogatott kukorica kukorica, nagykereskedelem, élelmiszer-pattogatott kukorica, citromle, citromkoncentrátum, napraforgó, bor, pezsgő, koncentrátumok, borsó, szójabab, cc, központ, krakkó, megbízható partner, import, export, kereskedelem, Magyarország, tönköly, tönkölyliszt, Lacrimae christi, tömegbor, Christus rex, maxi kukorica, takács pattogatott kukorica, műtrágya, műtrágyák, mezőgazdasági műtrágyák, ásványi műtrágyák, NPK, Polifoska 8, Polifoska 6, utra 10 , tarnogran"
};
